import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import x from '../assets/twitter2.png';
import logo from '../assets/logo.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const xLink = () => {
    window.open("https://twitter.com/FROGY_TOKEN");
}

class Footer extends Component {

    render() {
        return (

                <div class="boxWrap2Footer">

                    <div class="footer">

                        <img class="logoF" src={logo} />
                        <div class="iconsF">
                            <img onClick={xLink} src={x} />

                        </div>

                        <div class="copyright">Copyright © 2024 FROGY. All Rights Reserved</div>
                    </div>

                </div>
        )
    }
}

export default Footer;

