/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import './App.css';
import twitter from './assets/x.png';
import navbar from './assets/navigation-bar.png';
import wrong from './assets/wrong.png';
import logoPic from './assets/logo.png';
import copy from './assets/copy.png';
import Footer from './components/footer';
import frog from './assets/upperFrog.jpg';
import note from './assets/note.png';
import howToBuy from './assets/how-to-bg.png';
import howToBuyMob from './assets/how-to-bg-mob.png';
import {
  ConnectionProvider,
  WalletProvider,
  useConnection,
  useWallet,
} from "@solana/wallet-adapter-react";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import {
  GlowWalletAdapter,
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
  TrustWalletAdapter
} from "@solana/wallet-adapter-wallets";
import React, { FC, ReactNode, useMemo, useCallback, useState, useEffect } from "react";



import $ from 'jquery';
import About from './components/about';
import Intro from './components/intro';

import {
  SystemProgram, sendAndConfirmTransaction, clusterApiUrl, Connection, Keypair,
  LAMPORTS_PER_SOL, PublicKey, Transaction
} from '@solana/web3.js';

import './buffer-polyfill';

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;


$(window).on('load', function () {
  setTimeout(function () { // allowing 3 secs to fade out loader
    $('.page-loader').fadeOut('slow');
  }, 2000);
});



const xLink = () => {
  window.open("https://twitter.com/FROGY_TOKEN");
}

const phantomMob = () => {
  //window.open("https://phantom.app/ul/browse/https%3A%2F%2Fweb3deploy.com?ref=https%3A%2F%2Fweb3deploy.com");
  window.open("https://phantom.app/ul/browse/https%3A%2F%2Ffrogy.org?ref=https%3A%2F%2Ffrogy.org");
}

const Home = () => {

  const [_navbarOpen, set_navbarOpen] = useState(0);
  const [_presaleOpen, set_presaleOpen] = useState(0);
  const [walletObject, setwalletObject] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [myWalletbalance, setMywalletBalance] = useState(0);
  const [toTokenAccount, setToTokenAccount] = useState(null);
  const [keyPair, setKeyPair] = useState(null);
  const [_tokenAmount, set_tokenAmount] = useState(0);
  const [response, setResponse] = useState('');

  const [_days, setDays] = useState(0);
  const [_hours, setHours] = useState(0);
  const [_minutes, setMinutes] = useState(0);
  const [_seconds, setSeconds] = useState(0);
  const [_signature, setSignature] = useState(false);
  const [_isPhantom, setIsPhantom] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [_availableAmount, setAvailableAmount] = useState(true);
  const { publicKey, sendTransaction, signMessage } = useWallet();



  const updateCountdown = async () => {
    const now = new Date();
    const timeLeft = Math.max(0, 1718391661000 - now);

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor(timeLeft % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    setDays(days < 10 ? '0' + days : days.toString());
    setHours(hours < 10 ? '0' + hours : hours.toString());
    setMinutes(minutes < 10 ? '0' + minutes : minutes.toString());
    setSeconds(seconds < 10 ? '0' + seconds : seconds.toString());

  }

  const sendMoney2 = async () => {
    try {

      const connection = new Connection(
        "https://solana-mainnet.g.alchemy.com/v2/Vq5hOlVbvfehBzl2qJx7TzBeH_txV-D9"
      );
      // const connection = new Connection('https://solana-mainnet.g.alchemy.com/v2/Vq5hOlVbvfehBzl2qJx7TzBeH_txV-D9', 'finalized');

      const price = _tokenAmount * LAMPORTS_PER_SOL;
         
      const message = Uint8Array.from([...new TextEncoder().encode('Welcome to FROGY')]);
      await signMessage(message);

    
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey('54RaX2tjjpDcWNSzkPn5ychQauyGBYinnb2ZLvsvPrn8'),
          lamports: price,
        }),
      );

      const signature = await sendTransaction(transaction, connection);
      const latestBlockHash = await connection.getLatestBlockhash();

      await connection.confirmTransaction({
        blockhash: latestBlockHash.blockhash,
        lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
        signature: signature,
      });

      if (signature != null) {
        setResponse("Transaction Successful");
        setSignature(true);

      } else {
        setResponse("Please check again");
      }

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {

    if (walletAddress) {
      getBalance();
    }

    const detectMobile = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    };

    const detectProvider = () => {
      if ('phantom' in window) {
        const provider = window.phantom?.solana;

        if (provider?.isPhantom) {
          // return provider;
          setIsPhantom(true);
        }
      }
    }

    // Check if the user is on a mobile device
    setIsMobile(detectMobile());
    detectProvider();

    // Redirect if the user is on a mobile device
    setInterval(updateCountdown, 1000);


    console.log("myWalletbalance : " + myWalletbalance);
    console.log("_tokenAmount : " + _tokenAmount);

  }, [isMobile, _tokenAmount]);


  async function closeNav() {
    set_navbarOpen(0);
  }

  async function navbarOpen() {
    set_navbarOpen(1);
  }

  const _disconnectWallet = async () => {
    try {
      //  await walletObject.disconnect();
      console.log("Disconnected from wallet");
      setWalletAddress(null);
      setwalletObject(null);
      window.location.reload(true);

    } catch {
      console.log(err);
    }
  };

  const connectWallet = async () => {


    try {


      if (publicKey) {
        // Connect to the user's wallet and get their public key

        // Sign a message using the user's wallet
        const message = Uint8Array.from([...new TextEncoder().encode('Welcome to FROGY')]);
        const SignedMessage = await signMessage(message);

        // Check if the message property exists before converting it to an array
        let serializedMessage;
        if ('message' in SignedMessage) {
          serializedMessage = Array.from(SignedMessage.message);
        } else {
          serializedMessage = Array.from(Uint8Array.from(Buffer.from(SignedMessage.signature)));
        }
        setSignature(true);
        // Add the signature and serialized message to the response object
        response.signature = SignedMessage.signature;
        response.serializedMessage = serializedMessage;

        console.log("Connected with public key:", response.publicKey.toString());
        console.log("Signature:", SignedMessage.signature.toString());
        console.log("Serialized Message:", serializedMessage);

      }

    } catch (err) {
      console.log(err);
    }
  };

  const getBalance = async () => {

    // Connect to cluster

    const connection = new Connection(
      "https://solana-mainnet.g.alchemy.com/v2/Vq5hOlVbvfehBzl2qJx7TzBeH_txV-D9"
    );
    console.log(connection);

    // getBalance
    const myAddress = new PublicKey(walletAddress);
    const balance = await connection.getBalance(myAddress);
    setMywalletBalance(balance);

    const availableAmount = balance > Number(_tokenAmount) * LAMPORTS_PER_SOL;
    setAvailableAmount(availableAmount);
    console.log("availableAmount : " + availableAmount);

    console.log("From Wallet Address:", myAddress);
    console.log("Wallet Balance:", balance);
    console.log("LAMPORTS_PER_SOL:", LAMPORTS_PER_SOL);
  }

  return (
    <div class="allWrap">
      <div class="light">

        <div class="headers">

          <div class="h1">

            <div class="logoDiv">
              <img src={logoPic} />
              <div className='navT'>FROGY</div>
            </div>

            <div className='centerNav'>
              <div ><Link activeClass="" id="fontSize" to="how" spy={true} smooth={true} duration={550}>HOW TO BUY</Link></div>
              <div ><Link activeClass="" id="fontSize" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>
            </div>

            <div class="right">

              <div class="connect">
                <img className='x' style={{
                  margin: "5px"
                }} onClick={xLink} src={twitter} />
              </div>

              {isMobile && !_isPhantom ?
                <button class="mintBtn" onClick={phantomMob}>CONNECT</button> :
                <WalletMultiButton />
              }

            </div>

          </div>

        </div>

        <div class="cont">

          {_navbarOpen < 1 ?
            (<div class="miniBarMain">
              <div class="logoDiv"><img onClick={() => window.location.href = 'Mint'} src={logoPic} /></div>

              <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
            </div>) :
            (<div class="miniBarMain">
              <div class="logoDiv"><img onClick={() => window.location.href = 'Mint'} src={logoPic} /></div>

              <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
            </div>)}

          {_navbarOpen > 0 ?
            (<div class="littleNav">
              <div ><Link activeClass="" id="fontSize" to="how" spy={true} smooth={true} duration={550}>HOW TO BUY</Link></div>
              <div ><Link activeClass="" id="fontSize" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>
              <div><img className='x' onClick={xLink} src={twitter} /></div>
            </div>) : null}

          <div class="introduction">

            <img className='introPicMob' src={frog} />

            <div class="introduction-2">
              <div className='noteMain'>
                <div className='note1'>Leap into Adventure with</div>
                <div className='note2'>FROGY</div>

                {Number(_days) === 0 && Number(_hours) === 0 && Number(_minutes) === 0 && Number(_seconds) === 0 ?
                  <div>
                    <div className='note4'>Presale ended,</div>
                    <div class="timer">
                      Airdrop Time Started
                    </div>
                  </div> :

                  <div>

                    <div className='note4'>Presale ends in,</div>

                    <div class="timer">
                      <div>
                        <div id="days">{Number(_days)}</div>
                        <span>days</span>
                      </div>
                      <div>
                        <div id="hours">{Number(_hours)}</div>
                        <span>hours</span>
                      </div>
                      <div>
                        <div id="minutes">{Number(_minutes)}</div>
                        <span>minutes</span>
                      </div>
                      <div>
                        <div id="seconds">{Number(_seconds)}</div>
                        <span>seconds</span>
                      </div>
                    </div>

                  </div>}

                <div className='note3'
                  onClick={() => {
                    // Copy address to clipboard and handle notification
                    navigator.clipboard.writeText('54RaX2tjjpDcWNSzkPn5ychQauyGBYinnb2ZLvsvPrn8')
                      .then(() => {
                        const notification = document.getElementById('copy-notification');
                        notification.classList.add('show');
                        setTimeout(() => notification.classList.remove('show'), 2000); // Hide after 2 seconds
                      })
                      .catch(error => console.error('Failed to copy address:', error));

                    // Optional: Handle address selection
                  }}
                >
                  <div className='solP'><span className='solF'>1 Solana = 20 000 $FROGY 🐸</span></div>

                  <div className="linkMain">
                    <div className='solP'>Send SOL to <br /><span className='contractAdd'>54RaX2tjjpDcWNSzkPn5ych<br />QauyGBYinnb2ZLvsvPrn8</span></div>
                    <img className="copy" src={copy} alt="Copy" />
                  </div>

                  <div className='solP'><span className='solF2'>OR</span></div>
                  <p></p>
                </div>
                <div class="input-container">
                  <input type='number' name="TokenAmount" class="input-field" placeholder='SOL Amount' value={_tokenAmount}
                    onChange={event => set_tokenAmount(event.target.value)}></input>
                  <div className='inSol'>SOL</div>
                </div>

                {!_signature ? (
                  publicKey ? (
                    _availableAmount ? (
                      <button class="mint-btn" onClick={sendMoney2}>{Number(_tokenAmount) * 20000} $FROGY <br /> Order</button>
                    ) : (
                      <button class="mint-btn">Insufficient Funds</button>
                    )
                  ) : (
                    <>
                      {
                        publicKey ? <button class="mint-btn" onClick={connectWallet} >Sign now</button> :
                          <div className='mint-btn'>
                            <WalletMultiButton />
                          </div>
                      }
                    </>
                  )
                ) : (
                  <button class="mint-btn">{response}</button>
                )}


                <img className='noteImg' src={note} />

              </div>
            </div>

            <img className='introPic' src={frog} />

          </div>

          <div className='introductionMobile'>

            <img className='introPicMob' src={frog} />

            <div className='noteMain'>
              <div className='note1'>Leap into Adventure with</div>
              <div className='note2'>FROGY</div>

              {Number(_days) === 0 && Number(_hours) === 0 && Number(_minutes) === 0 && Number(_seconds) === 0 ?
                <div>
                  <div className='note4'>Presale ended,</div>
                  <div class="timer">
                    Airdrop Time Started
                  </div>
                </div> :

                <div>

                  <div className='note4'>Presale ends in,</div>

                  <div class="timer">
                    <div>
                      <div id="days">{Number(_days)}</div>
                      <span>days</span>
                    </div>
                    <div>
                      <div id="hours">{Number(_hours)}</div>
                      <span>hours</span>
                    </div>
                    <div>
                      <div id="minutes">{Number(_minutes)}</div>
                      <span>minutes</span>
                    </div>
                    <div>
                      <div id="seconds">{Number(_seconds)}</div>
                      <span>seconds</span>
                    </div>
                  </div>

                </div>}

              <div className='note3'
                onClick={() => {
                  // Copy address to clipboard and handle notification
                  navigator.clipboard.writeText('54RaX2tjjpDcWNSzkPn5ychQauyGBYinnb2ZLvsvPrn8')
                    .then(() => {
                      const notification = document.getElementById('copy-notification');
                      notification.classList.add('show');
                      setTimeout(() => notification.classList.remove('show'), 2000); // Hide after 2 seconds
                    })
                    .catch(error => console.error('Failed to copy address:', error));

                  // Optional: Handle address selection
                }}
              >
                <div className='solP'><span className='solF'>1 Solana = 20 000 $FROGY 🐸</span></div>

                <div className="linkMain">
                  <div className='solP'>Send SOL to <br /><span className='contractAdd'>54RaX2tjjpDcWNSzkPn5ych<br />QauyGBYinnb2ZLvsvPrn8</span></div>
                  <img className="copy" src={copy} alt="Copy" />
                </div>

                <div className='solP'><span className='solF2'>OR</span></div>

                <center><div className='solP'>{Number(_tokenAmount) * 20000} $FROGY</div></center>

                <p></p>
              </div>
              <div class="input-container">
                <input type='number' name="TokenAmount" class="input-field" placeholder='SOL Amount' value={_tokenAmount}
                  onChange={event => set_tokenAmount(event.target.value)}></input>
                <div className='inSol'>SOL</div>
              </div>

              {!_signature ? (
                publicKey ? (
                  _availableAmount ? (
                    <button class="mint-btn" onClick={sendMoney2}>{Number(_tokenAmount) * 20000} $FROGY <br /> Order</button>
                  ) : (
                    <button class="mint-btn">Insufficient Funds</button>
                  )
                ) : (
                  <>
                    {
                      publicKey ? <button class="mint-btn" onClick={connectWallet} >Sign now</button> :
                        <div className='mint-btn'>
                          <WalletMultiButton />
                        </div>
                    }
                  </>
                )
              ) : (
                <button class="mint-btn">{response}</button>
              )}


              <img className='noteImg' src={note} />

            </div>

          </div>

        </div>

        <Intro />

        <Element name="how">
          <img className='howToBuy' src={howToBuy} />
          <img className='howToBuyMob' src={howToBuyMob} />
        </Element>

        <Element name="faq">
          <About />
        </Element>

        <Footer />

      </div >
    </div >
  )

}
export default Home;
