import React, { useRef, Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import note1 from '../assets/note1.png';
import note2 from '../assets/note2.png';
import note3 from '../assets/note3.png';

class Intro extends Component {

  render() {
    return (

      <div className='blueBgMain'>
        <div className='blueBg'>
          
        <div class="aboutMain--2">
            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />
          </div>

          <div className='aboutMain--2Mob'>

            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />

            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />

            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />

            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />

            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />

            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />

            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />

            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />

            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />

            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />


            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />

            <img className='leftI' src={note1} />
            <img className='middle' src={note2} />
            <img className='rightI' src={note3} />

          </div>
        </div>

      </div>
    )
  }
}

export default Intro;

